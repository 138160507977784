import React from 'react'
import PageHeader from '../../components/PageHeader'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { CREATE_DATA_SET_MUTATION } from '../../graphql/queries/data_set'
import Spin from '../../../../components/common/ui/Spin'
import DynamicForm from '../../components/DynamicForm'
import {
  CreateDataSetMutation,
  CreateDataSetMutationVariables,
} from 'app/javascript/components/graphql'

export const dataSetFormData = [
  {
    name: 'name',
    label: 'Name',
    type: 'string',
    required: true,
    defaultValue: '',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    required: true,
    defaultValue: '',
  },
  {
    name: 'definition',
    label: 'Definition',
    type: 'json',
    required: true,
    defaultValue: JSON.stringify({
      exploreUrl: '',
    }),
  },
]

const CreateAgent = () => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const history = useHistory()
  const pages = [{ title: 'Data Sets', to: '/data_sets' }, { title: 'Create new data set' }]

  const [isSaving, setIsSaving] = React.useState(false)
  const [createDataSet] = useMutation<CreateDataSetMutation, CreateDataSetMutationVariables>(
    CREATE_DATA_SET_MUTATION
  )

  const handleSave = async ({
    name,
    description,
    definition,
  }: {
    name: string
    description: string
    definition: string
  }) => {
    setIsSaving(true)

    try {
      const { data } = await createDataSet({
        variables: {
          name: name.trim(),
          description: description.trim(),
          definition: definition.trim(),
        },
      })
      window.toastr.success('Data Set added successfully')
      history.push('/data_set/' + data['createDataSet']['dataSet']['uuid'])
    } catch (error) {
      window.toastr.error('Error adding data set')
      history.push('/data_sets')
    }
    setIsSaving(false)
  }

  return (
    <React.Fragment>
      <PageHeader title="Create Agent" breadCrumbs={pages} />
      <div className="max-w-2xl">
        <DynamicForm ref={formRef} formData={dataSetFormData} handleSubmit={handleSave} />
        <button
          type="submit"
          onClick={() => formRef.current?.submitForm()}
          className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
          disabled={isSaving}
        >
          {isSaving ? <Spin /> : 'Create'}
        </button>
      </div>
    </React.Fragment>
  )
}

export default CreateAgent
